html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "bubble";   /*Can be any text*/
  src: local("bubble_pixel-7"),
    url("./Assets/bubble_pixel-7.ttf") format("truetype");
}


.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
